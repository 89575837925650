import { Col, Container, Row } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { AppConst } from "consts";

interface IMember {
  avatar: string;
  name: string;
  position: string;
  href: string;
}

const TeamSection: React.FC = () => {
  const members: IMember[] = [
    {
      avatar: "./static/image/redscope.jpeg",
      name: "REDSCOPE",
      position: "FOUNDER / OWNER",
      href: "https://twitter.com/REDSCOPE_",
    },
    {
      avatar: "./static/image/kirsh.png",
      name: "KIRSH",
      position: "FOUNDER / OWNER",
      href: "https://twitter.com/TNAKirsh",
    },
	{
      avatar: "./static/image/keemstar.png",
      name: "KEEMSTAR",
      position: "OWNER",
      href: "https://twitter.com/KEEMSTAR",
    },
    {
      avatar: "./static/image/sposta.jpg",
      name: "SPOSTA",
      position: "ADVISOR",
      href: "https://twitter.com/MatSposta",
    },
    {
      avatar: "./static/image/mtg.png",
      name: "ECLIPSE",
      position: "DEVELOPMENT",
      href: "#",
    },
	{
      avatar: "./static/image/mtg.png",
      name: "BLOB",
      position: "DEVELOPMENT",
      href: "#",
    },
    {
      avatar: "./static/image/aalysady.png",
      name: "Aalasady",
      position: "Artist/Animation",
      href: "https://twitter.com/aalasady_",
    },
    {
      avatar: "./static/image/3seater.png",
      name: "3seater",
      position: "Design",
      href: "https://twitter.com/3seaterr",
    },
    {
      avatar: "./static/image/loganatwork.jpg",
      name: "LoganAtWork",
      position: "Head of Communications",
      href: "https://twitter.com/LoganAtWork",
    },
  ];

  return (
    <div className="team-section">
      <Container className="pb40">
        <Row>
          <h2 className="center color-white pt80">Team</h2>
        </Row>
        <Row>
          {members.map((m, i) => (
            <Member {...m} key={i} />
          ))}
        </Row>
      </Container>
    </div>
  );
};

const Member: React.FC<IMember> = (props) => {
  const [inViewRef, inView] = useInView({
    delay: AppConst.InViewDelay,
    rootMargin: AppConst.InViewMargin,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <Col xs={12} sm={6} md={4} lg={4} xl={2} ref={inViewRef}>
      <a href={props.href} style={{ color: "unset" }}>
        <div className="member-avatar">
          <motion.img
            src={props.avatar}
            alt="avatar"
            animate={controls}
            initial="hidden"
            variants={{
              hidden: { scale: 0.5 },
              visible: { scale: 1 },
            }}
            transition={{ duration: 1 }}
          />
        </div>
        <motion.div
          className="member-name"
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 1 }}
        >
          {props.name}
        </motion.div>
        <motion.div
          className="member-position"
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 1 }}
        >
          {props.position}
        </motion.div>
      </a>
    </Col>
  );
};

export default TeamSection;

import { Container, Nav, Navbar } from 'react-bootstrap';

const Header: React.FC<{
	bannerRef: any;
	whoRef: any;
	communicationRef: any;
	mintRef: any;
	roadmapRef: any;
}> = (props) => {
	// const [navColor, setNavColor] = useState('transparent');
	// const breakpoint = useBreakpoint();

	// useLayoutEffect(() => {
	// 	function updatePosition() {
	// 		if (window.pageYOffset > 300) {
	// 			setNavColor('dark');
	// 		} else if (window.pageYOffset <= 300) {
	// 			setNavColor('transparent');
	// 		}
	// 	}
	// 	window.addEventListener('scroll', updatePosition);
	// 	updatePosition();
	// 	return () => window.removeEventListener('scroll', updatePosition);
	// }, []);

	return (
		<Navbar
			fixed="top"
			expand="lg"
			variant="dark"
			bg="dark"
			// bg={[SIZE_XS, SIZE_SM, SIZE_MD].includes(breakpoint) ? 'dark' : navColor}
			style={{
				transition: 'all .5s ease',
				WebkitTransition: 'all .5s ease',
				MozTransition: 'all .5s ease',
			}}
		>
			<Container id="header">
				<a onClick={() => props.bannerRef.current?.scrollIntoView()}>
					<img src="./static/image/logo.svg" alt="MTG" />
				</a>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link
							className="color-white center"
							onClick={() => props.whoRef.current?.scrollIntoView()}
						>
							What
						</Nav.Link>
						<Nav.Link
							className="color-white center"
							onClick={() => props.communicationRef.current?.scrollIntoView()}
						>
							Community
						</Nav.Link>
						<Nav.Link
							className="color-white center"
							onClick={() => props.roadmapRef.current?.scrollIntoView()}
						>
							Roadmap
						</Nav.Link>
						<Nav.Link className="color-white center" href="https://app.morethangamersnft.io/" target="_blank">
							Staking
						</Nav.Link>
						<Nav.Link className="color-white center" href="https://store.morethangamersnft.io/" target="_blank">
							Merch
						</Nav.Link>
						<Nav.Link className="color-white center" href="https://game.morethangamersnft.io/" target="_blank">
							Game
						</Nav.Link>
					</Nav>
					<Nav>
						<Nav.Link className="center" href="https://www.instagram.com/nftmtg/" target="_blank">
							<img src={'./static/image/instagram.png'} width="30" height="30" alt="instagram" />
						</Nav.Link>
						<Nav.Link className="center" href="https://discord.gg/6wQ2rHKAuq" target="_blank">
							<img src={'./static/image/discord.svg'} width="30" height="30" alt="discord" />
						</Nav.Link>
						<Nav.Link className="center" href="https://twitter.com/NFTMTG" target="_blank">
							<img src={'./static/image/twitter.svg'} width="30" height="30" alt="twitter" />
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default Header;

import React from "react";
import "./App.css";
import BannerSection from "./features/landing/BannerSection";
import WhatSection from "./features/landing/WhatSection";
import CommunitySection from "./features/landing/CommunitySection";
import Header from "./features/landing/Header";
import RoadmapSection from "./features/landing/RoadmapSection";
import MintSection from "./features/landing/MintSection";
import FAQSection from "./features/landing/FAQSection";
import TeamSection from "./features/landing/TeamSection";
import Footer from "./features/landing/Footer";
import "react-perfect-scrollbar/dist/css/styles.css";

function App() {
  const bannerRef = React.createRef<HTMLDivElement>();
  const whoRef = React.createRef<HTMLDivElement>();
  const communicationRef = React.createRef<HTMLDivElement>();
  const mintRef = React.createRef<HTMLDivElement>();
  const roadmapRef = React.createRef<HTMLDivElement>();

  return (
    <div className="app">
      <Header
        bannerRef={bannerRef}
        whoRef={whoRef}
        communicationRef={communicationRef}
        mintRef={mintRef}
        roadmapRef={roadmapRef}
      />
      <BannerSection ref={bannerRef} />
      <WhatSection ref={whoRef} />
      <CommunitySection ref={communicationRef} />
      <MintSection ref={mintRef} />
      <RoadmapSection ref={roadmapRef} />
      <TeamSection />
      <FAQSection />
      <Footer />
    </div>
  );
}

export default App;

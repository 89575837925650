import { forwardRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { AppConst } from "consts";
import BorderBottom from "./BorderBottom";

const WhatSection = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const [inViewRef1, inView1] = useInView({
    delay: AppConst.InViewDelay,
    rootMargin: AppConst.InViewMargin,
  });
  const [inViewRef2, inView2] = useInView({
    delay: AppConst.InViewDelay,
    rootMargin: AppConst.InViewMargin,
  });
  const [inViewRef3, inView3] = useInView({
    delay: AppConst.InViewDelay,
    rootMargin: AppConst.InViewMargin,
  });

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    } else {
      controls1.start("hidden");
    }
  }, [controls1, inView1]);

  useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    } else {
      controls2.start("hidden");
    }
  }, [controls2, inView2]);

  useEffect(() => {
    if (inView3) {
      controls3.start("visible");
    } else {
      // controls3.start('hidden');
    }
  }, [controls3, inView3]);

  return (
    <div ref={ref}>
      <Container className="pt80">
        <Row>
          <Col xs={12} sm={12} md={12} lg={5} style={{ paddingRight: "80px" }}>
            <motion.div
              ref={inViewRef1}
              animate={controls1}
              initial="hidden"
              variants={{
                hidden: { x: -200, opacity: 0 },
                visible: { x: 0, opacity: 1 },
              }}
              transition={{ type: "spring", stiffness: 200 }}
            >
              <h2 className="color-white">What is MTG?</h2>
              <p className="color-white pt20">
                More than Gamers is a collection of 10,000 generative 3D side
                profile Characters fighting for Survival in the Gamers
                Metaverse. Smarts and determination through struggle and unique
                styles are what they’ll need to beat the game before their time
                is up.
              </p>
              <BorderBottom />
            </motion.div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7}>
            <motion.div
              ref={inViewRef2}
              animate={controls2}
              initial="hidden"
              variants={{
                hidden: { x: 200, opacity: 0 },
                visible: { x: 0, opacity: 1 },
              }}
              transition={{ type: "spring", stiffness: 200 }}
            >
              {/* <video
								style={{ width: '100%' }}
								controls
								controlsList="nodownload"
								src="./static/video/promo.mp4"
							/> */}
              <iframe
                width={"100%"}
                height="315"
                src="https://www.youtube.com/embed/F5ZN1CLWSs8"
                title="MORE THAN GAMERS LAUNCH"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </motion.div>
          </Col>
        </Row>
      </Container>
      <Container className="pt80 pb80">
        <Col xs={12} sm={12} md={12} lg={7}>
          <motion.div
            ref={inViewRef3}
            animate={controls3}
            initial="hidden"
            variants={{
              hidden: { y: 300, opacity: 0 },
              visible: { y: 0, opacity: 1 },
            }}
            transition={{ type: "spring", stiffness: 200 }}
          >
            <h2 className="pt20 color-white">Origin Story</h2>
            <p className="color-white pt20">
              The world as they know it is long gone. The rise of an elite
              royalty class has sent the rest of society into turmoil. Economic
              inequality, hate, and division have become the norm. Gamers must
              now battle across the metaverse to earn $Arcada to survive
              .$Arcada rules the game Society class is determined by how much
              $Arcada one holds. The more one plays, the more $Arcada they earn.
              $Arcada is needed to purchase all essential items. However, a
              rumor has been spreading… Collect enough $Arcada and you may be
              able to break out of the constraints of your economic class. Are
              the elite truly satisfied or are they also under the secret
              control of a higher power? But that is just the beginning of the
              journey…
            </p>
            <BorderBottom />
          </motion.div>
        </Col>
      </Container>
    </div>
  );
});

export default WhatSection;

import { motion, useAnimation } from 'framer-motion';
import { forwardRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { AppConst } from 'consts';

const BannerSection = forwardRef<HTMLDivElement, {}>((props, ref) => {
	const [inViewRef, inView] = useInView({
		delay: AppConst.InViewDelay,
		rootMargin: AppConst.InViewMargin,
	});

	const controls = useAnimation();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		} else {
			controls.start('hidden');
		}
	}, [controls, inView]);

	return (
		<div ref={ref} className="banner-wrapper">
			<div
				style={{ background: 'url(./static/image/banner.jpg)' }}
				className="banner-background"
				ref={inViewRef}
			></div>
			<motion.span
				className="banner-overlay"
				animate={controls}
				initial="hidden"
				variants={{
					hidden: { x: -600, opacity: 0, transition: { duration: AppConst.Duration } },
					visible: { x: 0, opacity: 1 },
				}}
				transition={{ type: 'spring', duration: 1 }}
			>
				10,000 Unique and diverse gamers competing for survival in the more than gamers metaverse
			</motion.span>
		</div>
	);
});

export default BannerSection;

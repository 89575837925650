import { forwardRef, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { AppConst } from 'consts';

const RoadmapSection = forwardRef<HTMLDivElement, {}>((props, ref) => {
	const [inViewRef1, inView1] = useInView({
		delay: AppConst.InViewDelay,
		rootMargin: AppConst.InViewMargin,
	});
	const [inViewRef2, inView2] = useInView({
		delay: AppConst.InViewDelay,
		rootMargin: AppConst.InViewMargin,
	});
	const [inViewRef3, inView3] = useInView({
		delay: AppConst.InViewDelay,
		rootMargin: AppConst.InViewMargin,
	});
	const [inViewRef4, inView4] = useInView({
		delay: AppConst.InViewDelay,
		rootMargin: AppConst.InViewMargin,
	});

	const controls1 = useAnimation();
	const controls2 = useAnimation();
	const controls3 = useAnimation();
	const controls4 = useAnimation();

	useEffect(() => {
		if (inView1) {
			controls1.start('visible');
		} else {
			controls1.start('hidden');
		}
	}, [controls1, inView1]);

	useEffect(() => {
		if (inView2) {
			controls2.start('visible');
		} else {
			controls2.start('hidden');
		}
	}, [controls2, inView2]);

	useEffect(() => {
		if (inView3) {
			controls3.start('visible');
		} else {
			controls3.start('hidden');
		}
	}, [controls3, inView3]);

	useEffect(() => {
		if (inView4) {
			controls4.start('visible');
		} else {
			controls4.start('hidden');
		}
	}, [controls4, inView4]);

	const timeline = (
		<Col xs={1}>
			<div className="timeline">
				<div className="timeline-node"></div>
				<div className="timeline-path"></div>
			</div>
		</Col>
	);

	return (
		<div className="bg-grey" ref={ref}>
			<Container className="pt80 pb40" ref={ref}>
				<Row>
					<h2 className="center color-white">Roadmap</h2>
					<Container ref={inViewRef1}>
						<motion.div
							animate={controls1}
							initial="hidden"
							variants={{
								hidden: { y: 300, opacity: 0 },
								visible: { y: 0, opacity: 1 },
							}}
							transition={{ type: 'spring', duration: 0.5 }}
						>
							<Row>
								{timeline}
								<Col xs={11}>
									<h3 className="pt20 color-white">Q1 2022</h3>
									<p className="color-white">
									Sposta joins as Advisor
									</p>
									<p className="color-white">
									Keemstar Joins as Owner
									</p>
									<p className="color-white">
									Royal Bank of Gamers Established
									</p>
									<p className="color-white">
									Rarity Tools Ranking Launched
									</p>
									<p className="color-white">
									Gamer Sales Bot Rollout
									</p>
									<p className="color-white">
									Merch Store Launched
									</p>
									<p className="color-white">
									Weekly AMAs
									</p>
									<p className="color-white">
									Token Contract Deployed for $ARCADA
									</p>
									<p className="color-white">
									Nifty Gateway Marketing Rollout
									</p>
									<p className="color-white">
									Staking Protocal Launched
									</p>
									<p className="color-white">
									Royal Games Concluded
									</p>
									<p className="color-white">
									March Madness Challenge
									</p>
								</Col>
							</Row>
						</motion.div>
					</Container>

					<Container ref={inViewRef4}>
						<motion.div
							animate={controls4}
							initial="hidden"
							variants={{
								hidden: { y: 300, opacity: 0 },
								visible: { y: 0, opacity: 1 },
							}}
							transition={{ type: 'spring', duration: 0.5 }}
						>
							<Row>
								{timeline}
								<Col xs={11}>
									<h3 className="pt20 color-white">Q2 2022</h3>
									<p className="color-white">
										<b>TOKEN UTILITY</b>
									</p>
									<p className="color-white">
										Merchandise (digital/physical). Entry into games.
									</p>
									<p className="color-white">
										<b>PARTNER LAUNCH</b>
									</p>
									<p className="color-white">
										Brand partner Activation. Collab launch.
									</p>
									<p className="color-white">
										<b>TOURNAMENTS</b>
									</p>
									<p className="color-white">
										Big prizes and giveaways. Achieve roles + level up within MTG ecosystem.
									</p>
									<p className="color-white">
										<b>AIRDROPS</b>
									</p>
									<p className="color-white">
										Special airdrop for active stakers. Airdropped assets to play a role in future gamification.
									</p>
									<p className="color-white">
										<b>CONFERENCES</b>
									</p>
									<p className="color-white">
										MTG to appear at upcoming conferences. Community event and activation.
									</p>
									<p className="color-white">
										<b>GAMES</b>
									</p>
									<p className="color-white">
										IGO launch to expand MTG asset offering. Launch mini-games. Development of P2E.
									</p>
								</Col>
							</Row>
						</motion.div>
					</Container>

					<Container ref={inViewRef2}>
						<motion.div
							animate={controls2}
							initial="hidden"
							variants={{
								hidden: { y: 300, opacity: 0, transition: { delay: 0 } },
								visible: { y: 0, opacity: 1 },
							}}
							transition={{ type: 'spring', duration: 0.5 }}
						>
							<Row>
								{timeline}
								<Col xs={11}>
									<h3 className="pt20 color-white">Q3/Q4 2022</h3>
									<p className="color-white">
										The back half of the year will see MTG continue to work towards its goal of bringing together the gaming and Web3 worlds. This will be highlighted by a $100,000 mini game tournament.
									</p>
									<p className="color-white">
										<b>REBRANDING</b>
									</p>
									<p className="color-white">
										New branding rollout, Establishing tone of voice, positioning and brand DNA. Clothing, collectibles and related brand merchandise launch.
									</p>
									<p className="color-white">
										<b>GAMERVERSE</b>
									</p>
									<p className="color-white">
										Planned IGO to further expand the MTG ecosystem.
									</p>
									<p className="color-white">
										<b>ASSET INTEGRATION</b>
									</p>
									<p className="color-white">
										MTG assets will be introduced and usable in the Gamerverse. Asset attributes will be defined and explained within the context of the new environment.
									</p>
									<p className="color-white">
										<b>GAME LAUNCH</b>
									</p>
									<p className="color-white">
										Launch of $100,000 mini game for MTG holders.
									</p>
									<p className="color-white">
										<b>COMMUNITY EVENTS</b>
									</p>
									<p className="color-white">
										Live meet-ups. Local gamer tournaments. Conference event and gathering.
									</p>
								</Col>
							</Row>
						</motion.div>
					</Container>
				</Row>
			</Container>
		</div>
	);
});

export default RoadmapSection;

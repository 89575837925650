import { Col, Row, Spinner } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { forwardRef, useEffect, useState } from "react";
import { AppConst } from "consts";

const MintSection = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const [inViewRef, inView] = useInView({
    delay: AppConst.InViewDelay,
    rootMargin: AppConst.InViewMargin,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="mint" ref={ref}>
      <Row
        className="parallax"
        style={{
          backgroundImage: 'url("./static/image/nft-mtg.png")',
        }}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={3}
          className="mint-text"
          ref={inViewRef}
        >
          <motion.div
            className="mint-content"
            animate={controls}
            initial="hidden"
            variants={{
              hidden: {
                opacity: 0,
                transition: { duration: AppConst.Duration },
              },
              visible: { opacity: 1 },
            }}
            transition={{ type: "spring", duration: 2 * AppConst.Duration }}
          >
            <h2 className="color-white">MINT YOUR MTG NFT</h2>
            <p className="color-white pt20">
              10,000 Gamers available for minting at 0.20 ETH.
              <br />
              Presale: 23rd December 2021, 1pm EST (7000 Whitelisted NFTs)
              <br />
              Public Sale: 24th December 2021, 1pm EST (3000 NFTs)
            </p>

            {/* <BorderBottom /> */}
            <Row className="supply-row">
              <p>Total Supply: 10,100 / 10,100</p>
            </Row>

            <div className="mint-button">Sold Out!</div>
          </motion.div>
        </Col>
      </Row>
    </div>
  );
});

export default MintSection;

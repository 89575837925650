interface IBorderBottomProps {
	width?: number;
	height?: number;
}

const BorderBottom: React.FC<IBorderBottomProps> = ({ width = 70, height = 5 }) => {
	return <div style={{ width: `${width}%`, borderTop: `solid ${height}px #f44336` }}></div>;
};

export default BorderBottom;

import useBreakpoint, { SIZE_XS } from 'app/useBreakpoint';
import { Col, Container, Row } from 'react-bootstrap';
import BorderBottom from './BorderBottom';

const Footer: React.FC = () => {
	const breakPoints = useBreakpoint();

	return (
		<Container className="pb40">
			<div className="pt20 pb10">
				<BorderBottom height={2} width={100} />
			</div>
			<Row>
				<Col
					xs={12}
					sm={6}
					className={`color-white pt20 ${breakPoints === SIZE_XS ? 'center' : ''}`}
				>
					© Copyright 2022 MTG
				</Col>
				<Col xs={12} sm={6} className={`pt20 ${breakPoints === SIZE_XS ? 'center' : 'right'}`}>
					<div>
						<a href="https://www.instagram.com/nftmtg/" target="_blank">
							<img
								src={'./static/image/instagram.png'}
								width="30"
								height="30"
								alt="instagram"
								className="mr15"
							/>
						</a>
						<a href="https://discord.gg/6wQ2rHKAuq" target="_blank">
							<img
								src={'./static/image/discord.svg'}
								width="30"
								height="30"
								alt="discord"
								className="mr15"
							/>
						</a>
						<a href="https://twitter.com/NFTMTG" target="_blank">
							<img
								src={'./static/image/twitter.svg'}
								width="30"
								height="30"
								alt="twitter"
								className="mr15"
							/>
						</a>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;

import { forwardRef, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { AppConst } from 'consts';
import BorderBottom from './BorderBottom';

const CommunitySection = forwardRef<HTMLDivElement, {}>((props, ref) => {
	const [inViewRef1, inView1] = useInView({
		delay: AppConst.InViewDelay,
		rootMargin: AppConst.InViewMargin,
	});
	const [inViewRef2, inView2] = useInView({
		delay: AppConst.InViewDelay,
		rootMargin: AppConst.InViewMargin,
	});

	const controls1 = useAnimation();
	const controls2 = useAnimation();

	useEffect(() => {
		if (inView1) {
			controls1.start('visible');
		} else {
			controls1.start('hidden');
		}
	}, [controls1, inView1]);

	useEffect(() => {
		if (inView2) {
			controls2.start('visible');
		} else {
			controls2.start('hidden');
		}
	}, [controls2, inView2]);

	return (
		<div className="bg-black pb80" ref={ref}>
			<Container className="pt80">
				<Row>
					<Col xs={12} md={12} lg={6}>
						<div className="img-container center" ref={inViewRef1}>
							<motion.img
								className="img1"
								src="./static/image/nft-1.png"
								alt="nft-1"
								animate={controls1}
								initial="hidden"
								variants={{
									hidden: { x: -100, opacity: 0, transition: { delay: 0 } },
									visible: { x: 0, opacity: 1 },
								}}
								transition={{ type: 'spring', duration: AppConst.Duration }}
							/>
							<motion.img
								className="img2"
								src="./static/image/nft-2.png"
								alt="nft-2"
								animate={controls1}
								initial="hidden"
								variants={{
									hidden: { x: 100, opacity: 0, transition: { delay: 0 } },
									visible: { x: 0, opacity: 1 },
								}}
								transition={{
									type: 'spring',
									duration: AppConst.Duration,
									delay: AppConst.Duration,
								}}
							/>
							<motion.img
								className="img3"
								src="./static/image/nft-3.png"
								alt="nft-3"
								animate={controls1}
								initial="hidden"
								variants={{
									hidden: { y: -100, opacity: 0, transition: { delay: 0 } },
									visible: { y: 0, opacity: 1 },
								}}
								transition={{
									type: 'spring',
									duration: AppConst.Duration,
									delay: 2 * AppConst.Duration,
								}}
							/>
						</div>
					</Col>
					<Col xs={12} md={12} lg={6} ref={inViewRef2}>
						<motion.div
							animate={controls2}
							initial="hidden"
							variants={{
								hidden: { opacity: 0, scale: 0.3 },
								visible: { opacity: 1, scale: 1 },
							}}
							transition={{ type: 'spring', duration: AppConst.Duration }}
						>
							<h2 className="color-white pt40">Join our discord group</h2>
							<p className="color-white pt20">
								The MTG Community is a hub for Gamers from all walks of life, join the discord to
								stay ontop of all news and announcements. All news regarding the MTG’S drop will
								appear here first, do not miss out!
							</p>
							<BorderBottom />
							<motion.div
								whileHover={{ scale: 1.1 }}
								whileTap={{ scale: 0.9 }}
								className="w150 pt20"
							>
								<a href="https://discord.gg/6wQ2rHKAuq" target="_blank">
									<img
										className="discord-button w150"
										src="./static/image/discord-button.svg"
										alt="discord"
									/>
								</a>
							</motion.div>
						</motion.div>
					</Col>
				</Row>
			</Container>
		</div>
	);
});

export default CommunitySection;
